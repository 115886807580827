import React, {useState} from 'react'
import { Spinner } from 'react-bootstrap'
import '../styles/newsletter.css'

function NewsLetter() {

    const [subscribe, setSubscribe] = useState({
        name: '',
        email: ''
    })

    const [error, setError] = useState({
        status: false,
        message: ''
    })

    const [success, setSuccess] = useState({
        message: ''
    })

    const [spinner, setSpinner] = useState(false);

    const handleChange = (e) => {
        let value = e.target.value
        if(e.target.name === 'name') {
            setSubscribe((prevState) => {
                return {...prevState, name: value }
              });    
        } else if(e.target.name === 'email') {
            setSubscribe((prevState) => {
                return {...prevState, email: value }
              });
        }
        setError((prevState) => {
            return {...prevState, status: false, message: '' }
        });
        setSuccess((prevState) => {
            return {...prevState, message: ''}
        });
    }
    
    const handleSubscribe = async () => {
        
        if(!subscribe.name) {
            setError((prevState) => {
                return {...prevState, status: true, message: 'Please enter your name' }
              });
        } else if ((!subscribe.email) || !(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(subscribe.email))) {
            setError((prevState) => {
                return {...prevState, status: true, message: 'Please enter a valid email' }
              });
        } else {
            
            setSpinner(true);

            let formData = new FormData();
            formData.append('api_key', 't4AYXM4X5RcK3frypG1N');
            formData.append('name', subscribe.name);
            formData.append('email', subscribe.email);
            formData.append('list', 'MTRCdl81gjE6h4bN1F8c8A');
            formData.append('boolean', 'true');

                setTimeout(() => { 

                    fetch("https://mailer.crampete.com/subscribe", { mode: 'no-cors', body: formData, method: "post" }).then((res) => {
                    if(res) {
                        setSuccess((prevState) => {
                            return {...prevState, message: 'Thank you for subscribing!'}
                        });
                    } else {
                        setSuccess((prevState) => {
                            return {...prevState, message: 'Something went wrong, please try again!'}
                        });
                    }
                }).catch( (e) => console.error('Error:',e));

                setSpinner(false);

            }, 1000);
            
        }
        
    }

    return (
        <div className="newsletterWrapper flex-col mt-5">
            <div className="newsletter flex-col center">
                <div className="form flex-col">
                    <h3 className="mb-3">Sign up to our newsletter!</h3>
                    <img src="/images/crampete-blog-subscription.png" alt="blog subscription" />
                    <input className="mb-3" type="text" name="name" value={subscribe.name} onChange={handleChange} placeholder="Enter your full name"/>
                    <input className="mb-1" type="email" name="email" value={subscribe.email} onChange={handleChange} placeholder="Enter your email address"/>
                    <div id="subscriptionEmail">{error.message}</div>
                    {!spinner ? <button type="submit" onClick={handleSubscribe}>Subscribe</button> : <button>Subscribe <Spinner animation="border" variant="white" size="sm" /></button>}
                    <div id="subscriptionResult">{success.message}</div>
                </div>
            </div>
        </div>
    )
}

export default NewsLetter
