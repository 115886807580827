import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Card, Spinner } from "react-bootstrap"
import NewsLetter from "../components/NewsLetter"
import Md4Card from "../components/md4card"
import { quizkyCrampeteAPI } from "../config"
import { Field, Form, Formik } from "formik"
import * as Yup from "yup"

const BlogSideBarFormSchema = Yup.object().shape({
  name: Yup.string().required("Please enter your full name"),
  email: Yup.string()
    .required("Please enter a valid email")
    .email("Please enter a valid email"),
  number: Yup.number()
    .required("Please enter a valid number")
    .positive("Please enter a valid number")
    .integer("Please enter a valid number"),
  course: Yup.string().required("Please select a course"),
})

export const query = graphql`
  query($slug: String!, $category: String!) {
    blogDataset(SlugURL: { eq: $slug }) {
      Category
      Content
      Excerpt
      FeaturedImageURL
      Id
      SlugURL
      Title
      PostedDate
      SEO {
        param
        value
      }
    }
    allBlogDataset(
      filter: { Category: { eq: $category }, SlugURL: { ne: $slug } }
      limit: 4
    ) {
      edges {
        node {
          Id
          Title
          SlugURL
          FeaturedImageURL
          Excerpt
        }
      }
    }
  }
`

const Blog = ({ data }) => {
  const queryString = global.window?.location.search
  let urlParams = new URLSearchParams(queryString)

  const [utmParams] = useState({
    utmSource: urlParams.get("utm_source") || "",
  })
  let utmSource = utmParams.utmSource ? utmParams.utmSource : "organic"
  const sourceUrl = global.window?.location.href

  const blog = data.blogDataset
  const blogSlugUrl = blog.SlugURL
  const relatedBlogs = data.allBlogDataset.edges


  const title =
    (blog.SEO && blog.SEO.find(({ param }) => param === "Title")?.value) || ""
  const description =
    (blog.SEO &&
      blog.SEO.find(({ param }) => param === "Description")?.value) ||
    ""
  const url =
    (blog.SEO && blog.SEO.find(({ param }) => param === "Url")?.value) || ""
  const image =
    (blog.SEO && blog.SEO.find(({ param }) => param === "Image")?.value) || ""
  const keywords =
    (blog.SEO && blog.SEO.find(({ param }) => param === "Keywords")?.value) ||
    ""
  const author =
    (blog.SEO && blog.SEO.find(({ param }) => param === "Author")?.value) || ""
  const schema = `{"@context":"https://schema.org","@type":"Article","headline":"${title}","image":"${image}","author":{"@type":"Person","name":"Crampete"},"publisher":{"@type":"Organization","name":"Crampete","logo":{"@type":"ImageObject","url":"https://www.crampete.com/images/logo.png"}},"datePublished":"${blog.PostedDate ? blog.PostedDate.split(" ")[0] : ""
    }","dateModified":"${blog.PostedDate ? blog.PostedDate.split(" ")[0] : ""
    }","mainEntityOfPage":{"@id":"${url}#webpage"}}`

  const breadCrumbSchema = `{"@context":"https://schema.org/","@type":"BreadcrumbList","@id":"${url}#breadcrumb","itemListElement":[{"@type":"ListItem","position":1,"item":{"@type":"WebPage","@id":"https://www.crampete.com/blog/","url":"https://www.crampete.com/blog/","name":"Home"}},{"@type":"ListItem","position":2,"item":{"@type":"WebPage","@id":"${url}","url":"${url}","name":"${title}"}}]}`

  const webPageSchema = `{"@context":"https://schema.org/","@type":"WebPage","@id":"${url}#webpage","url":"${url}","name":"${title}","datePublished":"${blog.PostedDate ? blog.PostedDate.split(" ")[0] : ""
    }","dateModified":"${blog.PostedDate ? blog.PostedDate.split(" ")[0] : ""
    }","description":"${description}","breadcrumb":{"@id":"${url}#breadcrumb"},"inLanguage":"en-US","potentialAction":[{"@type":"ReadAction","target":["${url}"]}]}`

  const newsArticle = `{
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    "url": "https://www.crampete.com/blogs/brainvalley-edtech-online-testing-assessment-platform/",
    "publisher":{
       "@type":"Organization",
       "name":"Crampete",
       "logo":"https://www.crampete.com/images/logo.svg"
    },
    "headline": "Brainvalley Edtech: Technology partner for educational organisations ",
    "description": "Brainvalley edtech designs web based test assessment tools for students, coaching institutes and corporate clients. It was founded by Shankar prabhu in the year 2011 and ranked 547 among 1827 competitors.",
    "mainEntityOfPage": "https://www.crampete.com/blogs/brainvalley-edtech-online-testing-assessment-platform/",
    "articleBody": "Brainvalley Edtech: Online testing assessment platform for Corporates and Organisations ",
    "image":[
       "https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/blog/brainvalley-edtech-online-testing-assessment-platform-for-corporates.webp",
       "https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/blog/Collaboration-of-Brainvalley-Edtech-with-Crampete.webp"
    ],
    "datePublished":"2023-03-03T20:30:54+00:00"
 }`

  const productSchema = `{
  "@context": "http://www.schema.org",
  "@type": "product",
  "brand": "Crampete",
  "name": "Reviews",
  "image": "https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/blog/best-software-courses-for-freshers.png",
  "description": "Best Software Courses for Freshers in 2023 discussed in length,discussing the fees, eligibility, career options, learning and much more. Read the blog to know in detail about the same.",
  "aggregateRating": {
    "@type": "aggregateRating",
    "ratingValue": "5",
    "reviewCount": "17981"
  }
}`



  const [meta] = useState({
    title,
    description,
    image,
    keywords,
    url,
    author,
  })

  const [success, setSuccess] = useState({
    message: "",
  })

  const [formError, setFormError] = useState({
    message: "",
  })

  const [spinner, setSpinner] = useState(false)

  const handleSubmit = values => {
    const { name, email, number, course } = values

    const opts = {
      companyCode: "crampete",
      accountId: "master",
      studentName: name,
      emailId: email,
      mobileNumber: number,
      referenceCourseName: course,
      source: utmSource,
      url: sourceUrl,
    }

    setSpinner(true)

    setTimeout(() => {
      fetch(`${quizkyCrampeteAPI}/common-lead/zoho`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(opts),
      })
        .then(function (response) {
          return response.json()
        })
        .then(function (data) {
          if (data.success) {
            setSuccess(prevState => {
              return {
                ...prevState,
                message: "Thank you, we've received your details.",
              }
            })
          } else {
            setFormError(prevState => {
              return {
                ...prevState,
                message: "Something went wrong, please try again!",
              }
            })
          }
        })
        .catch(e => console.error("Error:", e))
      setSpinner(false)
    }, 1000)
  }

  return (
    <Layout
      title={meta.title}
      description={meta.description}
      image={meta.image}
      keywords={meta.keywords}
      url={meta.url}
      author={meta.author}
      schema={schema}
      breadCrumbSchema={breadCrumbSchema}
      webPageSchema={webPageSchema}
      newsArticle={blogSlugUrl === 'brainvalley-edtech-online-testing-assessment-platform' && newsArticle}
      productSchema={blogSlugUrl === 'best-software-courses-for-freshers' && productSchema}
    >
      <hr className="mt-0 mb-4 d-none d-md-block" />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {/* <div className="text-center">
              <Card.Img variant="top" className="singleBlogFeaturedImg" src={blog.FeaturedImageURL} />
            </div> */}
          </div>
          <div className="col-md-9 blogLayoutOuter">
            <div className="text-center">
              <Card.Img
                variant="top"
                className="singleBlogFeaturedImg"
                src={blog.FeaturedImageURL}
                alt={blog.Title}
              />
            </div>
            <div dangerouslySetInnerHTML={{ __html: blog.Content }} />
          </div>
          <div className="col-md-3">
            <div className="blog-form-wrapper">
              <div className="blog-form-wrapper-inner">
                <img
                  src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/blog/lead-form-image-person.png"
                  alt="Lead Form Person"
                />
                <p>
                  Accelerate Your Career with <span>Crampete</span>
                </p>
                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                    number: "",
                    course: "",
                  }}
                  onSubmit={handleSubmit}
                  validationSchema={BlogSideBarFormSchema}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <div
                        className={`${touched.name && errors.name ? "blogFormErr" : ""
                          }`}
                      >
                        <Field
                          autoComplete="off"
                          name="name"
                          placeholder="Full Name"
                        />
                      </div>
                      <div
                        className={`${touched.email && errors.email ? "blogFormErr" : ""
                          }`}
                      >
                        <Field
                          autoComplete="off"
                          name="email"
                          placeholder="Email Address"
                        />
                      </div>
                      <div
                        className={`${touched.number && errors.number ? "blogFormErr" : ""
                          }`}
                      >
                        <Field
                          autoComplete="off"
                          name="number"
                          placeholder="Phone No."
                        />
                      </div>
                      <div
                        className={`${touched.course && errors.course ? "blogFormErr" : ""
                          }`}
                      >
                        <Field as="select" name="course">
                          <option value="" disabled selected>
                            Select Course
                          </option>
                          <option value="Full Stack Development">
                            Full Stack Development
                          </option>
                          <option value="Data Science">Data Science</option>
                          <option value="Digital Marketing">
                            Digital Marketing
                          </option>
                          <option value="Front End">Front End</option>
                          <option value="Back End">Back End</option>
                          <option value="Other Courses">Other Courses</option>
                        </Field>
                      </div>
                      {!spinner ? (
                        <button className="btn btn-primary" type="submit">
                          Submit
                        </button>
                      ) : (
                        <button className="please-wait btn btn-primary">
                          Please wait{" "}
                          <Spinner
                            animation="border"
                            variant="light"
                            size="sm"
                          />
                        </button>
                      )}
                      {success.message && (
                        <div id="blogLeadResult">{success.message}</div>
                      )}
                      {formError.message && (
                        <div id="blogLeadValidation">{formError.message}</div>
                      )}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          {/* <div className="col-md-12 mt-4 blogLayoutOuter">
          </div> */}
        </div>
        <NewsLetter />
        <div className="row" id="relatedBlogs">
          <div className="col-md-12">
            <div className="text-center mb-5">
              <h2>Related Blogs</h2>
            </div>
          </div>
          {relatedBlogs.length > 0 &&
            relatedBlogs.map(blog => {
              return (
                <Md4Card
                  key={blog.node.Id}
                  title={blog.node.Title}
                  excerpt={blog.node.Excerpt}
                  slugURL={blog.node.SlugURL}
                  featuredImageURL={blog.node.FeaturedImageURL}
                />
              )
            })}
        </div>
      </div>
    </Layout>
  )
}

export default Blog
